import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="tsJmDX5ljNc" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <dl className="dlWorkCredit mb-0">
          <dt>施設名称：</dt>
          <dd>楢葉町×東京大学総合研究博物館連携ミュージアム 大地とまちのタイムライン</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>事業主：</dt>
          <dd>楢葉町・東京大学総合研究博物館</dd>
        </dl>
        <dl className="dlWorkCredit">
          <dt>展示デザイン・設計、制作・施工：</dt>
          <dd>丹青社</dd>
        </dl>
        <p>
          「大地とまちのタイムライン」は、東日本大震災で被災し、休館していた町立の歴史資料館を楢葉町と東京大学総合研究博物館との官学連携でリニューアルした常設のミュージアム。その中で、被災した楢葉町だからこそ伝えられるメッセージとして「危機と再生」をテーマに、3面の壁面映像と、楢葉町の地形模型へのプロジェクションマッピングを組み合わせた展示映像を制作。地球の誕生から現代まで繰り返してきたさまざまな「危機と再生」と、町を襲った東日本大震災という危機を照らし合わせ、これからの楢葉町について考えてもらいたいというメッセージを込めている。また、未来展示コーナーで上映する、楢葉町に住む人たちへのインタビューをまとめた映像も制作。
        </p>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Video Production'],
    awards: [],
    outline: OutlineContent,
    agency: '株式会社丹青社'
  };

  /*
  const relatedLinks = [
    {text: "", href: ""}
  ];
  */

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
      //relatedLinks={relatedLinks}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 4)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/naraha"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/naraha.*"}) {
      publicURL
    }
  }
`;
